import { Box } from 'grommet';
import { GetServerSideProps, NextPage } from 'next';
import { serverClient } from '@/lib/api/graphql/apollo';
import {
  AssetKindsDocument,
  AssetKindsQuery,
  AssetKindsQueryVariables,
  useAssetKindsQuery,
} from '@/lib/generated/graphql';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { buildAppUrl } from '@/lib/utils/urls/buildAppUrl';

const useRedirectToFirstAssetKind = (): boolean => {
  const router = useRouter();
  const { data, loading } = useAssetKindsQuery({
    variables: {
      first: 1,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const kind = data?.assetKinds?.edges?.[0]?.node;

    if (!kind) {
      return;
    }

    void router.replace(
      buildAppUrl('/assets/{id}/', {
        id: kind.id,
      }),
    );
  }, [data, router]);

  return loading;
};

type HomeProps = {
  error?: string;
};

const Home: NextPage<HomeProps> = ({ error }) => {
  useRedirectToFirstAssetKind();

  if (error) {
    console.warn(error);
  }

  return <Box></Box>;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  let error: string | null = null;

  try {
    const { data } = await serverClient.query<
      AssetKindsQuery,
      AssetKindsQueryVariables
    >({
      query: AssetKindsDocument,
      variables: {
        first: 1,
      },
      context: {
        headers: {
          cookie: ctx.req.headers.cookie,
        },
      },
      fetchPolicy: 'network-only',
    });

    const id = data.assetKinds?.edges?.[0]?.node?.id;
    if (!id) {
      error = 'No AssetKind ID could be found';
      return { props: { error } };
    }

    return {
      redirect: {
        destination: `/assets/${id}`,
        permanent: true,
      },
    };
  } catch (e) {
    error = JSON.stringify(e);
  }

  return { props: { error } };
};

export default Home;
